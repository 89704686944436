<template>
  <div class="layer-page-div">
    <div class="item-div" v-for="item in types" :key="item.id">
      <label>
        <input class="type-input" type="text" v-model="item.name" placeholder="请输入操作证类型名称" :disabled="item.isNotEdit">
      </label>
      <div>
        <input class="type-btn del-btn" v-if="Object.prototype.hasOwnProperty.call(item, 'id')" type="button" value="删除" @click="onDel(item)">
        <input class="type-btn" type="button" :value="item.isNotEdit ? '编辑' : '确定'" @click="onEdit(item)">
      </div>
    </div>
    <div class="add-div" @click="onAdd">
      <img src="../../assets/add-circle.png" alt=""/>
    </div>
  </div>
</template>

<script>
import { getCertificateTypes, postAddCertificateType, postEditCertificateType, postDelCertificateType } from "@/common/api"

export default {
  name: "CertificateTypeLayer",
  props: {
    layerid: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      types: []
    }
  },
  created() {
    this.doGetCertificateTypes()
  },
  methods: {
    doGetCertificateTypes() {
      getCertificateTypes()
        .then(data => {
          if(Array.isArray(data)){
            data.forEach(v => {
              v.isNotEdit = true
            })
          }

          this.types = Array.isArray(data) ? data : []
        })
        .catch(error => {
          this.$layer.close(layerid);
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询操作证类型失败");
          }
        });
    },
    onDel(item) {
      this.$layer.confirm(`是否删除操作证类型（${item.name}）？`, layerid => {
        postDelCertificateType({ id: item.id })
          .then(() => {
            this.$layer.msg("删除成功");
            this.doGetCertificateTypes()
            this.$layer.close(layerid);
          })
          .catch(error => {
            this.$layer.close(layerid);
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("删除失败");
            }
          });
      })
    },
    onEdit(item) {
      if(item.isNotEdit){
        item.isNotEdit = false
        return
      }

      const name = item.name.trim()
      if(name.length < 1){
        this.$layer.msg("请输入操作证类型名称");
        return
      }

      if(name.length > 100){
        this.$layer.msg("操作证类型名称不能超过100个字符");
        return
      }

      if(Object.prototype.hasOwnProperty.call(item, "id")){
        postEditCertificateType({ id: item.id, name: name })
          .then(() => {
            this.$layer.msg("修改操作证类型成功");
            this.doGetCertificateTypes()
          })
          .catch(error => {
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("修改操作证类型失败");
            }
          });
      }else{
        postAddCertificateType({ name: name })
          .then(() => {
            this.$layer.msg("添加操作证类型成功");
            this.doGetCertificateTypes()
          })
          .catch(error => {
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("添加操作证类型失败");
            }
          });
      }
    },
    onAdd() {
      this.types.push({ name: '', isNotEdit: false })
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #2c3e50;
  padding-top: 5px;
  box-sizing: border-box;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: min-content;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-items: center;
  .item-div {
    width: 180px;
    height: 80px;
    border: 1px solid #787878;
    border-radius: 3px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .type-input {
      height: 25px;
      width: 150px;
      padding: 0 5px;
      border: 1px solid #787878;
      border-radius: 3px;
    }
    .type-btn {
      width: 60px;
      border: none;
      border-radius: 3px;
      color: #ffffff;
      padding: 5px 0;
      background-color: #0066cc;
    }
    .del-btn {
      margin-right: 10px;
    }
  }
  .add-div {
    width: 180px;
    height: 80px;
    border: 1px solid #787878;
    border-radius: 3px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 32px;
      height: 32px;
    }
  }
}
</style>