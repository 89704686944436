<template>
  <div class="layer-page-div">
    <div class="item-div">
      <div class="left-div">姓名：{{ dataInfo.applicant }}</div>
      <div class="right-div">电话：{{ dataInfo.phone }}</div>
    </div>
    <div class="item-div">
      <div class="left-div">申请类型：{{ dataInfo.type == null ? '到期换证' : dataInfo.type }}</div>
      <div class="right-div">申请时间：{{ dataInfo.createTime }}</div>
    </div>
    <div class="address-div">
      <div>地址：</div>
      <div class="address-msg-div">{{ dataInfo.address }}</div>
    </div>
    <div class="card-div">
      <div>身份证：</div>
      <div class="id-card-div">
        <div class="id-card-img-div first-div">
          <auth-img ref="img1" class="id-card-img" :auth-src="getCertificateImgUrl(1)"/>
          <input class="btn-input" type="button" value="下载" @click="onDownloadImg($refs.img1)">
        </div>
        <div class="id-card-img-div">
          <auth-img ref="img2" class="id-card-img" :auth-src="getCertificateImgUrl(2)"/>
          <input class="btn-input" type="button" value="下载" @click="onDownloadImg($refs.img2)">
        </div>
      </div>
    </div>
    <div class="last-div">
      <div class="personal-msg-div">
        <div>申请人：</div>
        <div class="personal-div">
          <auth-img ref="img3" class="personal-img" :auth-src="getCertificateImgUrl(3)"/>
          <input class="btn-input" type="button" value="下载" @click="onDownloadImg($refs.img3)">
        </div>
      </div>
      <div v-if="dataInfo.applicant.length > 0 && dataInfo.type == null" class="other-div">
        <div>过期证件：</div>
        <div class="other-img-div">
          <auth-img ref="img4" class="other-img" :auth-src="getCertificateImgUrl(4)"/>
          <input class="btn-input" type="button" value="下载" @click="onDownloadImg($refs.img4)">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthImg from "../../components/AuthImg"
import { getApplyCertificateInfo, certificateImg } from "@/common/api"

export default {
  name: "CertificateInfoLayer",
  components: { AuthImg },
  props: {
    layerid: {
      type: String,
      default: ""
    },
    infoId: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      dataInfo: {
        type: null,
        applicant: '',
        phone: '',
        address: '',
        createTime: ''
      }
    }
  },
  created() {
    this.initApplyCertificateInfo()
  },
  methods: {
    initApplyCertificateInfo() {
      getApplyCertificateInfo({ id: this.infoId })
        .then(data => {
          this.dataInfo.applicant = data.applicant
          this.dataInfo.phone = data.phone
          this.dataInfo.address = data.address
          this.dataInfo.createTime = data.createTime
          if (Object.prototype.hasOwnProperty.call(data, "type")) this.dataInfo.type = data.type
        })
        .catch(error => {
          this.$layer.close(layerid);
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询申请详情失败，请稍后重试");
          }
        });
    },
    getCertificateImgUrl(type) {
      return certificateImg(this.infoId, type)
    },
    onDownloadImg(obj) {
      obj.saveImg()
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .item-div {
    display: flex;
    margin-bottom: 5px;
    .left-div {
      flex: 0.5;
      text-align: right;
      margin-right: 10px;
    }
    .right-div {
      flex: 0.5;
      text-align: left;
      margin-left: 10px;
    }
  }
  .address-div {
    padding: 0 20px;
    margin-bottom: 5px;
    .address-msg-div {
      height: 65px;
      padding: 3px;
      box-sizing: border-box;
      border: 1px solid #b1b1b1;
      border-radius: 3px;
      margin-top: 5px;
    }
  }
  .card-div {
    padding: 0 20px;
    margin-bottom: 5px;
    .id-card-div {
      display: flex;
      .id-card-img-div {
        width: 255px;
        height: 162px;
        position: relative;
        .id-card-img {
          width: 100%;
          height: 100%;
        }
        .btn-input {
          position: absolute;
          top: 3px;
          right: 3px;
        }
      }
      .first-div {
        margin-right: 20px;
      }
    }
  }
  .last-div {
    padding: 0 20px;
    display: flex;
    .personal-msg-div {
      margin-right: 20px;
      .personal-div {
        width: 255px;
        height: 240px;
        position: relative;
        .personal-img {
          width: 100%;
          height: 100%;
        }
        .btn-input {
          position: absolute;
          top: 3px;
          right: 3px;
        }
      }
    }
    .other-div {
      .other-img-div {
        width: 255px;
        height: 162px;
        position: relative;
        .other-img {
          width: 100%;
          height: 100%;
        }
        .btn-input {
          position: absolute;
          top: 3px;
          right: 3px;
        }
      }
    }
  }
}
</style>